

<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1" style="margin: auto 10px;">
<div class="flex-autorow flex-top flex-stretch player-channel">
  <div class="flex-item flex-1">
    <div class="push-bottom fx fx-slide-up fx-delay-3">
          <div class="push-bottom">
      <h4 class="text-nowrap fx fx-slide-left fx-delay-1">Contact</h4>
      <h5 style="font-size: 0.73rem;" class="text-nowrap fx fx-slide-left fx-delay-1">Remplissez ce formulaire pour contacter Enka Radio</h5>
    </div>
    </div>
  </div>
  </div>
        <div class="flex-autorow flex-top flex-stretch player-channel">
          <!-- station details -->
<div class="flex-item flex-1">
<div class="push-bottom">
  <div class="card flex-item flex-top flex-stretch fx fx-slide-up fx-delay-4 flex-1">
<form v-on:submit.prevent="submitForm">
<div class="form-group">
    <label for="nom">Nom + Prénom : </label>
    <input
      id="nom"
      v-model="form.nom"
      type="text"
      name="nom"
    >
</div>
<div class="form-group">
    <label for="email">Email : </label>
    <input
      id="email"
      v-model="form.email"
      type="text"
      name="email"
    >
</div>
<div class="form-group">
    <label for="pseudo">Sujet : </label>
    <input
      id="objet"
      v-model="form.objet"
      type="text"
      name="objet"
    >
</div>
<div class="form-group">
    <label for="message">Votre message : </label>
    <textarea v-model="form.message" id="message" name="message"></textarea>
</div>
<div class="form-group">
    <label for="captcha">Captcha : </label>
    <input
      id="captcha"
      v-model="form.captcha"
      type="text"
      name="captcha"
      placeholder="1 - 1 ?"
    >
</div>
<div class="form-group">
  <p>
    <input
      type="submit"
      value="Envoyer"
    >
  </p>
</div>
<span style="color:red" v-if="form.errors">{{ form.errors }}</span>
<span style="color:green" class="fx fx-slide-up fx-delay-3" v-if="form.success">{{ form.success }}</span>
</form>
      </div>
      </div>
      </div>


        </div>

        <!--<syncLyrics></syncLyrics>-->

      </section>
    </main>

  </div>
</template>

<script>
    /**
     * Home
     */
    import axios from 'axios';
    import {mapGetters, mapState} from 'vuex';
    import favBtn from "@/views/components/favBtn";
    import SongsHistory from "@/views/station/components/songsHistory";
    import MainSong from "@/views/station/components/mainSong";
    import footerPlayer from '@/views/station/components/footerPlayer'

    export default {
        name: 'dedicace',
        components: {
            favBtn,
            MainSong,
            SongsHistory,
            footerPlayer
        },
        data: () => {
            return {
                // toggles
                form: {
                    nom: '',
                    email: '',
                    objet: '',
                    message: '',
                    captcha: '',
                    errors: '',
                    success: ''
            }

            }
        },

   methods:{
        submitForm(){
            axios.post('https://ythomas.fr/enkaradio/api/post_contact.php', this.form)
                 .then((res) => {
                     console.log(res.data.code);
                     if (res.data.code == '1') {
                        this.form.success = res.data.message;
                     } else {
                        this.form.errors = res.data.message;
                     }
                     setTimeout(function() {
                      this.form.success= '';
                      this.form.errors= '';
                    }.bind(this), 4000);
                 })
                 .catch((error) => {
                  this.form.errors = "Une erreur est survenue. Si le problème persiste merci de nous contacter";
                     // error.response.status Check status code
                 }).finally(() => {
                     //Perform action in always
                 });
        }
    }

    }
</script>